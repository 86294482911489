@import url(https://fonts.googleapis.com/css?family=PT+Mono);
@import url(https://fonts.googleapis.com/css?family=Kurale);

body {
    background-color: black;
    /* color: #4ECCA3; */
    /* color: #8cd8bf; */
    color: #b7e5d6;
    /* height: 200vh; */
    font-family: "Kurale", serif;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#title {
    background-color: transparent;
    color: white;
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header {
    background: none;
    font-weight: bolder;
}

.intro-text {
    /* align-self: center; */
    position: absolute;
    top: 40%;
    pointer-events: none;
    user-select: none;
    transition: top 1s cubic-bezier(0.77, 0, 0.18, 1);
}
.intro-text * {
    display: inline-block;
    position: absolute;
    overflow: hidden;
}
.intro-txt {
    color: #00ff00af;
}
.inv-text {
    width: auto;
    flex-direction: column;
    align-items: center;
    font-family: PT mono;
    font-size: 3vw;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

/* Animation */
.intro-txt1 {
    border-right: solid 3px transparent;

    animation: animated-txt 1.5s steps(22, end) 1s 1 normal both,
        text-fade 1s steps(100, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 7,
        deanimated-txt 1.5s steps(22, end) 1s 1 normal both;

    animation-delay: 0s, 1.5s, 0s, 2.5s;
}

.intro-txt2 {
    border-right: solid 3px transparent;

    animation: animated-txt 1.5s steps(26, end) 1s 1 normal both,
        text-fade 1s steps(100, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 7,
        deanimated-txt 1.5s steps(26, end) 1s 1 normal both;

    animation-delay: 4s, 5.5s, 4s, 6.5s;
}

.intro-txt3 {
    border-right: solid 3px transparent;

    animation: animated-txt 1s steps(16, end) 1s 1 normal both,
        text-fade 1s steps(100, end) 1s 1 normal both,
        animated-cursor 600ms steps(22, end) 5,
        deanimated-txt 1s steps(16, end) 1s 1 normal both;

    animation-delay: 8s, 9s, 8s, 10s;
}
/* text animation */

@keyframes animated-txt {
    from {
        width: 0px;
    }
    to {
        width: 100%;
    }
}
@keyframes deanimated-txt {
    to {
        width: 0;
    }
}

/* cursor animations */

@keyframes animated-cursor {
    from {
        border-right-color: rgba(0, 255, 0, 0.75);
    }
    to {
        border-right-color: transparent;
    }
}

#magic {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: block;
    top: 0;
    left: 0;
    z-index: -9999;
    opacity: 0;
    transition: opacity ease-in 2s;
}

.playground {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: block;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
