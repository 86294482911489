@import "https://fonts.googleapis.com/css?family=PT+Mono";
@import "https://fonts.googleapis.com/css?family=Kurale";
body {
  color: #b7e5d6;
  background-color: #000;
  flex-direction: column;
  font-family: Kurale, serif;
  display: flex;
  overflow: hidden;
}

#title {
  color: #fff;
  height: 100vh;
  width: 100vw;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: relative;
}

.header {
  background: none;
  font-weight: bolder;
}

.intro-text {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: top 1s cubic-bezier(.77, 0, .18, 1);
  position: absolute;
  top: 40%;
}

.intro-text * {
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

.intro-txt {
  color: #00ff00af;
}

.inv-text {
  width: auto;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  font-family: PT mono;
  font-size: 3vw;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro-txt1 {
  border-right: 3px solid #0000;
  animation: 1.5s steps(22, end) both animated-txt, 1s steps(100, end) 1.5s both text-fade, .6s steps(22, end) 7 animated-cursor, 1.5s steps(22, end) 2.5s both deanimated-txt;
}

.intro-txt2 {
  border-right: 3px solid #0000;
  animation: 1.5s steps(26, end) 4s both animated-txt, 1s steps(100, end) 5.5s both text-fade, .6s steps(22, end) 4s 7 animated-cursor, 1.5s steps(26, end) 6.5s both deanimated-txt;
}

.intro-txt3 {
  border-right: 3px solid #0000;
  animation: 1s steps(16, end) 8s both animated-txt, 1s steps(100, end) 9s both text-fade, .6s steps(22, end) 8s 5 animated-cursor, 1s steps(16, end) 10s both deanimated-txt;
}

@keyframes animated-txt {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes deanimated-txt {
  to {
    width: 0;
  }
}

@keyframes animated-cursor {
  from {
    border-right-color: #00ff00bf;
  }

  to {
    border-right-color: #0000;
  }
}

#magic {
  width: 100%;
  height: 100vh;
  z-index: -9999;
  opacity: 0;
  transition: opacity 2s ease-in;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.playground {
  width: 100%;
  height: 100vh;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.css.map */
